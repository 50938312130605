import { Component, Injectable, Input, Inject, forwardRef } from '@angular/core';
import {FirebaseAuth} from 'angularfire2';

//import { RegisterComponent } from './register/register.component';
//import { BarcodeComponent }   from './barcode/barcode.component'; //KeyUpComponent_v1, 
import { ContendersComponent } from './contenders/contenders.component';
import { FirebreakComponent } from './firebreak/firebreak.component';
import { EventsComponent } from './+events';

@Component({
  moduleId: module.id,
  selector: 'blaze-app-app',
  templateUrl: 'blaze-app.component.html',
  styleUrls: ['blaze-app.component.css'],
  styles: ['sass/blaze.scss'],
  directives: [ContendersComponent, FirebreakComponent], //RegisterComponent, BarcodeComponent, KeyUpComponent_v1, ,
})

export class BlazeAppAppComponent {
  constructor (private _auth: FirebaseAuth) {}

  public doLogin () {
    // This will perform popup auth with google oauth and the scope will be email
    // Because those options were provided through bootstrap to DI, and we're overriding the provider.
    this._auth.login({
      //provider: AuthProviders.Google
    });
  }
  public mode:string = "Start";
  
  //@Input() model;
  name: string;
  //parentModel = { prop1: '1st prop', prop2: '2nd prop' }
  

  title = 'Fire| |Brake';
  tagline = "Time Real Time";
  modes = ["Register", "Start", "Finish", "Results"];
  footerBrand = "blaze real time &trade;";
  footerDetails = "Keeping it real since 2016";
  register = {
    firstName: '',
    lastName: '',
    email: '',
    barcode: ''
  }
  onChange(modeValue) {
    this.mode = modeValue;
    console.log("mode: " + this.mode);
  }

  //used as example of how register.ts gets data from app
  getName() {
    return this.name;
  }

  //used as example of how register.ts gets data from app
  getMode() {
    return this.mode;
  }
}
